import React from 'react'
import { Helmet } from 'react-helmet'

import styled from './privacy.module.css'

const LAST_UPDATE = '2020-05-10'

const Privacy = () => (
  <div className={styled.page}>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="robots" content="noindex" />
      <link rel="shortcut icon" type="image/x-icon" href="/ttimer/favicon.ico" />
      <link rel="apple-touch-icon" sizes="180x180" href="/ttimer/apple-touch-icon.png" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, minimum-scale=1, maximum-scale=1, user-scalable=no"
      />
      <title>Privacy Policy</title>
    </Helmet>
    <div className={styled.content}>
      <article>
        <h1>Privacy Policy</h1>
        <p>
          Sales provider ("we") "TTimer" a service provided on the App ("Service"). The following is the privacy policy
          ("Policy") regarding the handling of personal information of users of the Company.
        </p>
        <h2>Information Collection And Use</h2>
        <p>
          While using our Site, we may ask you to provide us with certain personally identifiable information that can
          be used to contact or identify you. Personally identifiable information may include, but is not limited to
          your name ("Personal Information").
        </p>
        <h2>Log Data</h2>
        <p>
          Like many site operators, we collect information that your browser sends whenever you visit our Site ("Log
          Data"). This Log Data may include information such as your computer's Internet Protocol ("IP") address,
          browser type, browser version, the pages of our Site that you visit, the time and date of your visit, the time
          spent on those pages and other statistics. In addition, we may use third party services such as Google
          Analytics that collect, monitor and analyze this.
        </p>
        <h2>Security</h2>
        <p>
          The security of your Personal Information is important to us, but remember that no method of transmission over
          the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable
          means to protect your Personal Information, we cannot guarantee its absolute security.
        </p>
        <h2>Changes To This Privacy Policy</h2>
        <p>
          This Privacy Policy is effective as of {LAST_UPDATE} and will remain in effect except with respect to any
          changes in its provisions in the future, which will be in effect immediately after being posted on this page.
          We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy
          Policy periodically. Your continued use of the Service after we post any modifications to the Privacy Policy
          on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound
          by the modified Privacy Policy. If we make any material changes to this Privacy Policy, we will notify you
          either through the email address you have provided us, or by placing a prominent notice on our website.
        </p>
        <h2>Contact Us</h2>
        <p>If you have any questions about this policy, please contact the following.</p>
        <p>Email： now276never0@gmail.com</p>
        <p>【Last updated: {LAST_UPDATE}】</p>
      </article>
    </div>
  </div>
)

export default Privacy
